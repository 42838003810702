import React, { FC, FunctionComponent } from "react"
import { observer } from "mobx-react-lite"

import { SwipeListView } from 'react-native-swipe-list-view';
import { Pressable, } from "react-native"

import { WebView } from 'react-native-webview';
import { WebView as WebViewForWeb } from 'react-native-web-webview';


import {
    AlertDialog, Button,
    Spacer, useDisclose
} from "native-base"


import {
    StatusBar, Text, Badge, BadgeText,
    View, Button as Button2, Box, Icon,
    Input, InputSlot, InputIcon, InputField,
    ButtonText, ButtonIcon, VStack, HStack,
    SearchIcon,
    Actionsheet,
    ScrollView,
    ActionsheetContent, ActionsheetDragIndicatorWrapper, ActionsheetDragIndicator,
    ActionsheetItem, ActionsheetBackdrop,
    ActionsheetItemText,
    ActionsheetIcon,
} from "@gluestack-ui/themed";

import { MaterialIcons, Ionicons, MaterialCommunityIcons, FontAwesome5, Octicons } from "@expo/vector-icons";

import { HomeStackParamList, useBackButtonHandler } from "../../navigators"
import { DrawerScreenProps } from "@react-navigation/drawer"
import { useStores } from "../../models"
import { ITEM_STATUS } from "../../voolks/types";
import { getIconItem, getItemTitle, openPDF, getIconItemPTW, getGPSCoords } from "../../utils/itemCommonFunctions";
import { AppState, Linking, Platform } from "react-native";
import * as Storage from "../../utils/storage";
import { customApp } from "../../config";
import { calcularVelocidadConexion } from "../../utils/general";

export const MeasurementScreen: FC<DrawerScreenProps<HomeStackParamList, "ItemsList">> = observer(function MeasurementScreen({ navigation, route }) {
    const fcName = "MeasurementScreen";
    console.log(fcName);
    const [viewActive, setViewActive] = React.useState<"charts" | "docs">("docs");
    const [isSwiping, setIsSwiping] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const [showActionsheet, setShowActionsheet] = React.useState(false);
    const { formsStore, itemsStore, statusStore, authenticationStore } = useStores();
    const user = authenticationStore.userInfo.user;
    const [alertDialog, setAlertDialog] = React.useState(undefined);
    const [infoModal, setInfoModal] = React.useState(false);
    const [itemsPending, setItemsPending] = React.useState<boolean | undefined>(undefined);
    const [mustSync, setMustSync] = React.useState<boolean | undefined>(false);
    const [myLocation, setMyLocation] = React.useState<{ latitude: number, longitude: number }>({ latitude: -34.939, longitude: -58.497 });
    const swipeRows: any[] = [];
    let swipeRow_selected: any;
    let my_items: any = [];
    let departments: any = [];
    React.useEffect(() => {
        getGPSCoords().then((gps: any) => {
            if (gps)
                setMyLocation({ latitude: gps.coords.latitude, longitude: gps.coords.longitude });
        });
    }, [])
    const setSpeedTest = (speed) => {
        statusStore.setFlashMessage("Velocidad de Conexion ".concat(speed, "Mbps"))
    }
    const leftButton = <></>;

    const goBack = () => {


    }

    useBackButtonHandler(goBack);

    const newItem = async (department, form) => {
        await itemsStore.new(department.id, form.id, undefined)
        _openItem(form, department);
    };

    const openItem = async (form, department, itemSelected: any = undefined) => {
        _openItem(form, department, itemSelected);
    }

    const _openItem = async (form, department, itemSelected: any = undefined) => {
        console.log(fcName, "_openItem", "Init");
        if (itemSelected)
            await itemsStore.activateItem(itemSelected)
        if (itemsStore.itemActiveIDX != undefined) {
            await itemsStore.makePayloadFromFormPages(form.pages);
            navigation.navigate("ItemDetails", {
                itemStartedAt: new Date().getTime(),
                formId: form.id,
                deptId: department.id,
                forReview: itemsStore.itemActive.status != ITEM_STATUS.draft.toString(),
                taskId: undefined,
                pageActive: 0,
                rnd: new Date().getTime()
            })
        }
        console.log(fcName, "_openItem", "Fin");
    }

    const removeItemsDialog = async (department, form, item: any = undefined) => {
        let lstIdx: number[] = [];
        let alert = { title: "", message: "" };
        if (item != undefined) {
            let itemIdx: number = itemsStore.getIDXofItem(item)
            if (itemIdx > -1) {
                lstIdx.push(itemIdx);
            }
            alert.title = `Borrar registro ${form.name}`;
            alert.message = `¿Estas seguro de borrar el registro  ${getItemTitle(form, item)}?`;
            setAlertDialog({
                ...alert,
                action: () => {
                    swipeRows.map(async (row) => {
                        await row?.closeRow();
                    });
                    lstIdx.sort((a, b) => { return b - a; }).forEach(idx => itemsStore.removeItem(idx));
                }
            })
        }
    }

    const renderItem = React.useCallback(
        (data, rowMap) => {
            const item = data.item;
            const idx = data.index;
            const department = departments.find((dept: any) => dept.id == data.item.deptId);
            if (department == undefined)
                return <></>;
            const form = (department.forms || []).find((form: any) => form.id == data.item.formId);
            if (form == undefined)
                return <></>;
            const _title = getItemTitle(form, item);
            if (search.trim().length > 0 && !(_title?.toUpperCase()?.includes(search.toUpperCase()) || form.name.toUpperCase()?.includes(search.toUpperCase())))
                return <></>;

            return (
                <VStack key={`SwipeRow${idx}`} borderBottomWidth={1} borderBottomColor={"$light300"} style={{ minHeight: 55, flexWrap: "wrap", backgroundColor: "white", justifyContent: "space-between", }}  >
                    <HStack py={10} width={"$full"} alignItems="center" bgColor={form.measurement.type == "point" ? "$white" : "$secondary0"}>
                        {getIconItem(item.status)}
                        <Pressable style={{ flexDirection: "row", alignItems: "center" }} onPress={() => openItem(form, department, item)} disabled={isSwiping}>
                            <VStack paddingHorizontal={3} maxWidth={statusStore.windowDimensions.width - (statusStore.windowDimensions.width >= 1500 ? 400 : 80)}>
                                <Text textBreakStrategy="simple" size="md">{_title}</Text>
                                <Text size="xs">{idx} {department.name}  {form.name}</Text>
                            </VStack>
                            {getIconItemPTW(item.statusPTW)}
                        </Pressable>
                        <Spacer></Spacer>
                        <Pressable onPress={() => openItem(form, department, item)}><Icon name="arrow-forward" as={Ionicons} size="md" /></Pressable>
                    </HStack>
                </VStack>
            )
        }
        ,
        [departments]);

    const ItemsListRows: FunctionComponent = () => {
        return (
            <SwipeListView
                useFlatList={true} windowSize={100} removeClippedSubviews={true} initialNumToRender={15}
                onSwipeValueChange={({ value }) => {
                    if (value !== 0) setIsSwiping(true); // Detecta swipe activo
                    else setIsSwiping(false); // Resetea al finalizar el swipe
                }}
                data={my_items} style={{ width: "100%" }}
                renderItem={renderItem}
                leftOpenValue={120}
                rightOpenValue={-60}
                renderHiddenItem={(data, rowMap) => {
                    const item = data.item;
                    const department = departments.find((dept: any) => dept.id == item.deptId);
                    if (department == undefined)
                        return <></>;
                    const form = (department.forms || []).find((form: any) => form.id == item.formId);
                    if (form == undefined)
                        return <></>;

                    return (
                        <View style={{
                            alignItems: 'center',
                            flexDirection: 'row',
                        }}>
                            <View style={{ minHeight: 45, flexWrap: "wrap", flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
                                <Button2 isDisabled={item.status != ITEM_STATUS.sent && item.id == undefined} borderRadius={0} width={55} height={55} p="$1" bgColor={"$success500"}
                                    variant="solid"
                                    key="itemsStore-openPDF"
                                    onPress={() => openPDF(itemsStore, item)}
                                >
                                    <ButtonIcon as={FontAwesome5} name="file-pdf" fontWeight="$extrabold" size="xl" color="#FFF" />
                                </Button2>
                            </View>
                            <View style={{ minHeight: 45, flexWrap: "wrap", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <Button2 isDisabled={item.status != ITEM_STATUS.sent && item.id == undefined} borderRadius={0} width={55} height={55} p="$1" bgColor={"$yellow500"}
                                    variant="solid"
                                    key="itemsStore-openPDF"
                                    onPress={() => openPDF(itemsStore, item, true)}
                                >
                                    <ButtonIcon as={Ionicons} name="mail" fontWeight="$extrabold" size="xl" color="#FFF" />
                                </Button2>
                            </View>
                            <Spacer></Spacer>
                            <View ><Button2 width={55} height={55} p="$1" bgColor={"$danger500"}
                                variant="solid"
                                key="itemsStore-removeItemsDialog"
                                onPress={() => removeItemsDialog(department, form, item)}
                            >
                                <ButtonIcon as={Ionicons} name="trash-outline" fontWeight="$extrabold" size="xl" color="#FFF" />
                            </Button2>
                            </View>

                        </View>
                    )
                }
                }
            />
        )
    }

    const handleSearch = (text: string) => {

        setSearch(text);
        console.log(text);
    }

    const cancelRef = React.useRef();
    if (statusStore.status == "pending")
        return (<></>);

    const mySubscriptions = formsStore.mySubscriptions();
    departments = mySubscriptions.subscriptions.reduce((lst, dept) => {
        const wfs = [];
        for (const form of dept.forms) {
            if (form.type == "measurement")
                wfs.push(form)
        }
        if (wfs.length > 0)
            lst.push({ "id": dept.id, "name": dept.name, forms: wfs })
        return lst;
    }, []);

    if (itemsPending == undefined) {
        my_items = itemsStore.myItems().reverse().filter(item => {
            const department = departments.find((dept: any) => dept.id == item.deptId);
            if (department == undefined)
                return false;
            const form = (department.forms || []).find((form: any) => form.id == item.formId);
            if (form == undefined)
                return false;

            let dateJob;
            if (form.workflow.scheduledDate && itemsStore.getPayloadElement(form.workflow.scheduledDate)?.value) {
                dateJob = new Date(itemsStore.getPayloadElement(form.workflow.scheduledDate).value);
            }
            if (dateJob == undefined)
                dateJob = item._startedAt;
            if (dateJob && dateJob.toISOString().substring(0, 10) != (new Date()).toISOString().substring(0, 10))
                return false
            return true;

        }).sort((a, b) => a.status.localeCompare(b.status));
    } else
        my_items = itemsStore.myItems().reverse().filter(item => (!itemsPending || item.status != "sent" || item.statusPTW == "waiting")).sort((a, b) => a.status.localeCompare(b.status));
    const someToUpload =
        my_items.reduce((counter, item) => {
            return counter + (item.status == ITEM_STATUS.closed ? 1 : 0)
        }, 0);

    return (<>
        <StatusBar backgroundColor={"#3700B3"} barStyle="light-content" />
        <Box safeAreaTop bg="primary.400" />
        <HStack bg="$primary400" px={0} py={0} justifyContent="space-between" alignItems="center" w="100%">
            <HStack alignItems="center" maxWidth={statusStore.windowDimensions.width - 140} >
                {leftButton}
                <VStack>
                    <Text color="white" fontSize={18} marginLeft={5} >
                        Certificación Digital
                    </Text>
                    <Text color="white" fontSize={10} marginLeft={5}>ult. sinc.: {new Date(mySubscriptions.lastSync).getYYYYMMddHHmmss()}</Text>
                </VStack>
            </HStack>
            <HStack>
                <Button2 width={50} height={50} p="$1" variant="solid"
                    key="Measurement-hel2"
                    onPress={async () => setInfoModal(true)}>
                    <ButtonIcon as={MaterialCommunityIcons} name="help-circle" fontWeight="$extrabold" size="xl" color="#FFF" />
                </Button2>

            </HStack>
        </HStack>
        <HStack bg="white" flex={1} pt={4}>
            {(Platform.OS == "web" || viewActive == "charts") ?
                <VStack px={0} py={0} my={0} mr={2} alignItems="center" w={Platform.OS == "web" ? "70%" : "100%"} borderWidth={1} borderColor={"$light100"} >
                    <HStack bg={"$light100"} justifyContent="flex-start" alignItems="center" w="100%" p={3} mb={2} >
                        <Icon name="bar-chart-sharp" size={"lg"} as={Ionicons} color={"$light500"} my={3} mx={5} /><Text fontWeight="bold" color="$light500">GESTION GLOBAL</Text>
                    </HStack>
                    <VStack>
                        {Platform.OS != "web" ?
                            <WebView
                                source={{ uri: 'http://localhost:3000/app/#/app/dashboard/departments/667f0da1d5b9b01c000c33be/graphs/67571f8477e1311c00cebe38' }}
                                style={{ width: statusStore.windowDimensions.width, height: (statusStore.windowDimensions.height - 150), }}
                            />
                            :
                            <WebViewForWeb
                                startInLoadingState={true}
                                source={{ uri: 'http://localhost:3000/app/#/app/dashboard/departments/667f0da1d5b9b01c000c33be/graphs/67571f8477e1311c00cebe38' }}
                                style={{ overflow: 'hidden', marginTop: 0, height: (statusStore.windowDimensions.height - 150), width: statusStore.windowDimensions.width * 0.69 }}
                                hideStatusBar={false}
                                hideAddressBar={false}
                                hideActivityIndicator={false}
                            />
                        }
                    </VStack>
                </VStack>
                : <></>
            }
            {(Platform.OS == "web" || viewActive == "docs") ?
                <VStack px={0} py={0} ml={2} alignItems="center" w={Platform.OS == "web" ? "29%" : "100%"} borderWidth={1} borderColor={"$light100"} >
                    <HStack bg={"$light100"} justifyContent="flex-start" alignItems="center" w="100%" p={3} mb={2}>
                        <Icon name="checklist" size={"lg"} as={Octicons} color={"$light500"} my={3} mx={5} /><Text fontWeight="bold" color="$light500">DOCUMENTOS EN CURSO</Text>
                        <Spacer></Spacer>
                        <VStack mr={1}>
                            {someToUpload > 0 ?
                                <Badge h={21} w={22} bg="$red600" borderRadius="$full" mb={-12} mr={0} zIndex={1}
                                    variant="solid" alignSelf="flex-end" ><BadgeText size="2xs" color="$white" fontWeight="bold">{someToUpload}</BadgeText>
                                </Badge> : null
                            }
                            <Button2 width={25} height={25} mr={20} p="$1" variant="link"
                                key="Measurement-upload"
                                onPress={async () => { Storage.removeItem("must_sync"); await itemsStore.upload({}); formsStore.getSubscriptions("measurement"); }}>
                                <ButtonIcon as={MaterialCommunityIcons} name="cloud-sync-outline" fontWeight="$extrabold" size="xl" color="$light500" />
                            </Button2>
                        </VStack>
                    </HStack>
                    <ItemsListRows></ItemsListRows>
                    <Spacer />
                    <HStack justifyContent="flex-end" width={"100%"}>
                        <Input w="75%" ml={5} variant="underlined">
                            <InputSlot mx={5} >
                                <InputIcon as={SearchIcon} />
                            </InputSlot>
                            <InputField placeholder={"Buscar..."} onChangeText={handleSearch} />
                        </Input>
                        <Spacer />
                        <Button2 margin={2} width={50} height={50} p="$1" borderRadius="$full" variant="solid" bgColor="$green600"
                            onPress={() => setShowActionsheet(true)}>
                            <ButtonIcon as={Ionicons} name="add" fontWeight="$extrabold" size="xl" color="#FFF" />
                        </Button2>
                        <Actionsheet isOpen={showActionsheet} onClose={() => setShowActionsheet(false)}>
                            <ActionsheetBackdrop />
                            <ActionsheetContent>
                                <ActionsheetDragIndicatorWrapper>
                                    <ActionsheetDragIndicator />
                                </ActionsheetDragIndicatorWrapper>
                                <Box w="100%" h={30} px={4} justifyContent="center">
                                    <Text fontSize={16} color="$gray500" >
                                        Indique tipo de documento a  crear:
                                    </Text>
                                </Box>
                                <ScrollView ><Box w={statusStore.windowDimensions.width - 20} >
                                    {departments.reduce((lst, dept, idX) => {
                                        let forms = [].concat(dept.forms);
                                        lst = lst.concat(
                                            forms.sort((a, b) => a.name.localeCompare(b.name))
                                                .map((f, idY) => <ActionsheetItem py="1" key={"btn_new".concat(idX, "-", idY)} onPress={() => newItem(dept, f)}>
                                                    <ActionsheetIcon as={MaterialCommunityIcons} name={f.measurement.type == "point" ? "format-list-checks" : "file-document-multiple"} />
                                                    <ActionsheetItemText>{f.name}</ActionsheetItemText>
                                                </ActionsheetItem>)
                                        );
                                        return lst;
                                    }, [])}</Box></ScrollView>
                            </ActionsheetContent>
                        </Actionsheet>
                    </HStack>

                </VStack>
                : <></>
            }
        </HStack>
        <HStack width={"100%"} backgroundColor="$light100" borderWidth={1} borderColor="$light300">
            <VStack >
                <HStack alignItems={"center"}>
                    <Button2 width={50} height={50} p="$1" variant="link" onPress={authenticationStore.signOut}>
                        <ButtonIcon as={MaterialIcons} name="logout" fontWeight="$extrabold" size="xl" color="$light500" />
                    </Button2>
                    <Text>{user?.firstName} {user?.lastName}</Text>
                </HStack>
            </VStack>
            <Spacer></Spacer>
            {(Platform.OS != "web" && viewActive == "charts") ?
                <Button2 width={50} height={50} p="$1" variant="link" onPress={() => setViewActive("docs")}>
                    <ButtonIcon as={Octicons} name="checklist" fontWeight="$extrabold" size="xl" color="$light500" />
                </Button2>
                : <></>}
            {(Platform.OS != "web" && viewActive == "docs") ?
                <Button2 width={50} height={50} p="$1" variant="link" onPress={() => setViewActive("charts")}>
                    <ButtonIcon as={Ionicons} name="bar-chart-sharp" fontWeight="$extrabold" size="xl" color="$light500" />
                </Button2>
                : <></>}
        </HStack>


        {alertDialog == undefined ? <></> :
            <AlertDialog leastDestructiveRef={cancelRef} isOpen={alertDialog != undefined} >
                <AlertDialog.Content>
                    <AlertDialog.CloseButton />
                    <AlertDialog.Header>{alertDialog?.title}</AlertDialog.Header>
                    <AlertDialog.Body>{alertDialog?.message}</AlertDialog.Body>
                    <AlertDialog.Footer>
                        <Button ref={cancelRef} onPress={() => setAlertDialog(undefined)}>
                            Cancelar
                        </Button>
                        <Button colorScheme="red" ml={3} onPress={() => { alertDialog?.action(); setAlertDialog(undefined) }}>
                            Aceptar
                        </Button>
                    </AlertDialog.Footer>
                </AlertDialog.Content>
            </AlertDialog>
        }

        <AlertDialog leastDestructiveRef={cancelRef} isOpen={infoModal} onClose={() => setInfoModal(false)} size={"xl"}>
            <AlertDialog.Content>
                <AlertDialog.CloseButton />
                <AlertDialog.Header>Descripción iconos</AlertDialog.Header>
                <AlertDialog.Body >
                    <VStack >
                        <HStack borderBottomWidth={1} borderBottomColor={"gray.300"} py={1} alignItems="center" >
                            {getIconItem("draft")}
                            <Text ml="24px">Registro nuevo para ser completado.</Text>
                        </HStack>
                        <HStack borderBottomWidth={1} borderBottomColor={"gray.300"} py={1} alignItems="center" >
                            {getIconItem("draft")}
                            {getIconItemPTW("pending")}
                            <Text width={"80%"} >Registro creado por otro usuario y que debo completar</Text>
                        </HStack>
                        <HStack borderBottomWidth={1} borderBottomColor={"gray.300"} py={1} alignItems="center" >
                            {getIconItem("draft")}
                            {getIconItemPTW("finished")}
                            <Text>Permiso aprobado, debo cerrar trabajo</Text>
                        </HStack>
                        <HStack borderBottomWidth={1} borderBottomColor={"gray.300"} py={1} alignItems="center" >
                            {getIconItem("closed")}
                            <Text width={"90%"} ml="24px">Registro completado listo para subirse al servidor.</Text>
                        </HStack>
                        <HStack borderBottomWidth={1} borderBottomColor={"gray.300"} py={1} alignItems="center" >
                            {getIconItem("closed")}
                            {getIconItemPTW("pending")}
                            <Text width={"90%"}>Registro creado por otro usuario, que ya complete y debo subir al servidor</Text>
                        </HStack>
                        <HStack borderBottomWidth={1} borderBottomColor={"gray.300"} py={1} alignItems="center" >
                            {getIconItem("sent")}
                            {getIconItemPTW("inprogress")}
                            <Text width={"90%"} >Registro en el que ya participe, y que continua su workflow</Text>
                        </HStack>
                        <HStack borderBottomWidth={1} borderBottomColor={"gray.300"} py={1} alignItems="center" >
                            {getIconItem("sent")}
                            {getIconItemPTW("waiting")}
                            <Text width={"80%"} >Registro en el que debo participar, pero esta en una etapa previa</Text>
                        </HStack>
                        <HStack borderBottomWidth={1} borderBottomColor={"gray.300"} py={1} alignItems="center" >
                            {getIconItem("sent")}
                            {getIconItemPTW("finished")}
                            <Text width={"90%"} >Registro con aprobación completa, pendiente fin de trabajo</Text>
                        </HStack>
                        <HStack borderBottomWidth={1} borderBottomColor={"gray.300"} py={1} alignItems="center" >
                            {getIconItem("sent")}
                            {getIconItemPTW("closed")}
                            <Text>Trabajo terminado</Text>
                        </HStack>
                        <HStack borderBottomWidth={1} borderBottomColor={"gray.300"} py={1} alignItems="center" >
                            {getIconItem("sent")}
                            {getIconItemPTW("reject")}
                            <Text >Solicitud rechazada</Text>
                        </HStack>
                        <HStack borderBottomWidth={1} borderBottomColor={"gray.300"} py={1} alignItems="center" >
                            {getIconItem("sent")}
                            {getIconItemPTW("error")}
                            <Text >Error en Workflow</Text>
                        </HStack>

                        <View style={{ flexDirection: "row", flexWrap: "wrap", backgroundColor: "#226073", paddingHorizontal: 10 }}>
                            <Text color="white" bold={true} alignSelf="center">Probar velocidad de conexión</Text> <Spacer />
                            <Button2 width={50} height={50} p="$1" variant="link" onPress={() => calcularVelocidadConexion(setSpeedTest, statusStore)} >
                                <ButtonIcon as={MaterialIcons} name="speed" fontWeight="$extrabold" size="xl" color="$red500" />
                            </Button2>
                        </View>

                        <View style={{ flexDirection: "row", flexWrap: "wrap", backgroundColor: "#000", padding: 10 }}>
                            <Text color="white" bold={true}>INSTRUCTIVOS</Text>
                        </View>
                        <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                            <View style={{ flexDirection: "row", padding: 10, minWidth: "50%" }} >
                                <Icon
                                    onPress={() => Linking.openURL("https://drive.google.com/file/d/1IqRKrADc9SsXKRSVxVsDCV0q6LTxRItQ/view?usp=drive_link")} as={<FontAwesome5 name="file-pdf" />} size={"lg"} mr="2" color={"primary.400"} />
                                <Text>Documentación </Text>

                            </View>
                            <View style={{ flexDirection: "row", padding: 10, minWidth: "50%" }} >
                                <Icon onPress={() => Linking.openURL("https://drive.google.com/file/d/1RSbKyjnx3WyJUqhKuWGv_wDR82fcZvmy/view?usp=drive_link")}
                                    as={<MaterialIcons name="video-library" />} size={"lg"} mr="2" color={"secondary.400"} />
                                <Text>Anexo A1 </Text>

                            </View>
                            <View style={{ flexDirection: "row", padding: 10, minWidth: "50%" }} >
                                <Icon onPress={() => Linking.openURL("https://drive.google.com/file/d/19HqCPAnnEqwwc-p6tJ85imapRl-maxNv/view?usp=drive_link")}
                                    as={<MaterialIcons name="video-library" />} size={"lg"} mr="2" color={"secondary.400"} />
                                <Text>Anexo A1 Cadena de aprobación </Text>

                            </View>
                            <View style={{ flexDirection: "row", padding: 10, minWidth: "50%" }} >
                                <Icon onPress={() => Linking.openURL("https://drive.google.com/file/d/14eTY5WTNr1N3QAjL5GASaK5uJVSpnmwO/view?usp=drive_link")}
                                    as={<MaterialIcons name="video-library" />} size={"lg"} mr="2" color={"secondary.400"} />
                                <Text>Anexo A1 - Habilitante </Text>

                            </View>
                            <View style={{ flexDirection: "row", padding: 10, minWidth: "50%" }} >
                                <Icon onPress={() => Linking.openURL("https://drive.google.com/file/d/14eTY5WTNr1N3QAjL5GASaK5uJVSpnmwO/view?usp=drive_link")}
                                    as={<MaterialIcons name="video-library" />} size={"lg"} mr="2" color={"secondary.400"} />
                                <Text>Anexo A1 - Cierre del Trabajo </Text>
                            </View>
                            <View style={{ flexDirection: "row", padding: 10, minWidth: "50%" }} >
                                <Icon onPress={() => Linking.openURL("https://drive.google.com/file/d/14PTl4X_6SeYY35q8z9eNX94D02IYwxCN/view?usp=drive_link")}
                                    as={<MaterialIcons name="video-library" />} size={"lg"} mr="2" color={"secondary.400"} />
                                <Text>Anexo A2 </Text>
                            </View>
                            <View style={{ flexDirection: "row", padding: 10, minWidth: "50%" }} >
                                <Icon onPress={() => Linking.openURL("https://drive.google.com/file/d/1IZV7c2Mg0l_TtuKfciHpGaScE35XDiHa/view?usp=drive_link")}
                                    as={<MaterialIcons name="video-library" />} size={"lg"} mr="2" color={"secondary.400"} />
                                <Text>Anexo A3 </Text>
                            </View>
                            <View style={{ flexDirection: "row", padding: 10, minWidth: "50%" }} >
                                <Icon onPress={() => Linking.openURL("https://drive.google.com/file/d/1tNYLKltjtscGLj2eehmb4IVqmjzQA_zF/view?usp=drive_link")}
                                    as={<MaterialIcons name="video-library" />} size={"lg"} mr="2" color={"secondary.400"} />
                                <Text>Anexo A4 </Text>
                            </View>
                        </View>
                    </VStack>
                </AlertDialog.Body>
            </AlertDialog.Content>
        </AlertDialog>

    </>
    )
})