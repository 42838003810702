import React, { FC } from 'react';
import { ElementBase, IElementBaseProps, initializeElement } from '..';
import { onPatch, } from 'mobx-state-tree';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { getDisplayAndValue } from '../../utils/itemCommonFunctions';
import { useDebouncedCallback } from 'use-debounce';
import { Text, Icon, HStack } from "@gluestack-ui/themed"


export const FormulaElement: FC<IElementBaseProps> = (({ ...props }) => {
    const {
        itemsStore, element,
        payload, checkAndSetPayload, errorList, setErrorList, setPayload,
    } = initializeElement(props);

    const isAdvanced = element.templateData.isAdvanced;
    const formula = isAdvanced ? element.templateData.formulaAdvanced : element.templateData.formula;
    formula.variables = formula.variables.filter(v => v.dataSource != undefined);
    const handleChange = (value: string | undefined) => {
        if (!isAdvanced)
            value = value == undefined ? "" : (value != undefined && (!isNaN(Number(value)) || value == '-') ? value : "");
        let errorElement = itemsStore.getErrorElement(element, value);
        setErrorList(errorElement.errorList)
        setPayload({ ...payload, value: value, valid: true });

    }

    const calculate = () => {
        try {
            let total = undefined;
            let recordsLength = 1;

            if (element.templateData.fxScope != undefined && element.templateData.fxScope.length>0){                
                recordsLength = itemsStore.itemActive.payload.get(element.templateData.fxScope).value.length;
            }else
                element.templateData.fxScope=undefined;

            for (let idx = 0; idx < recordsLength; idx++) {
                if (!formula.formula.includes("return "))
                    formula.formula = `return ${formula.formula}`;
                let fn = `((${formula.variables.map((v: any) => v.variable).join()})=>{${formula.formula}})(${formula.variables.map((v: any) => {
                    const objVar = (element.templateData.fxScope) ? itemsStore.getPayloadElement(v.dataSource.id, element.templateData.fxScope, idx) : itemsStore.getPayloadElement(v.dataSource.id, props.parentId, props.recordIdx)
                    if (objVar && objVar.value) {
                        let value = objVar.value;
                        if (isAdvanced)
                            value = JSON.stringify(value);
                        else {
                            if (objVar.type == "select") {
                                value = getDisplayAndValue(value).value;
                            }
                            if (objVar.type == "multiple") {
                                value = getDisplayAndValue(value[0]).value;
                            }
                        }
                        return value
                    }
                    return isAdvanced ? "undefined" : 0;
                }).join()})`
                console.log(idx, fn);
                let result = eval(fn);
                let resultStandard = 0;
                console.log("calculate", result)
                if (!isAdvanced && result != undefined && !isNaN(result)) {
                    if (formula.decimals != undefined && !isNaN(formula.decimals))
                        result = parseFloat(result.toFixed(formula.decimals))
                    else
                        result = parseFloat(result.toFixed(6))

                    if (!isNaN(result)) {
                        resultStandard = result;//tuve que hacer esto porque cuando es cero me lo limpia, al hacer el set.
                    }
                }

                if (isAdvanced)
                    total = result;
                else if (element.templateData.fxScopeResume && element.templateData.fxScopeResume == "MAX" && (!total || total < resultStandard))
                    total = resultStandard;
                else if (element.templateData.fxScopeResume && element.templateData.fxScopeResume == "MIN" && (!total || total > resultStandard))
                    total = resultStandard;
                else if (!element.templateData.fxScopeResume || !["MAX", "MIN"].includes(element.templateData.fxScopeResume))
                    total = resultStandard + (total || 0);
            }
            if (!isAdvanced) {
                if (total != undefined && element.templateData.fxScopeResume && element.templateData.fxScopeResume == "AVG") {
                    total = total / recordsLength;
                    if (formula.decimals != undefined && !isNaN(formula.decimals))
                        total = parseFloat(total.toFixed(formula.decimals))
                    else
                        total = parseFloat(total.toFixed(6))
                }
                if (total == undefined)
                    total = 0;
            }
            console.log("calculate", "out")
            console.log("FormulaElement", element.id, "TOTAL FORMULA", total)
            handleChange(total);

        } catch (e) { console.log(e, element.name, "error") }
    }

    const calculateDebouneced = useDebouncedCallback(calculate, 5);

    React.useEffect(() => {
        calculate();
        let _disposerOnPatch = onPatch(itemsStore, patch => {
            let mustRecalculate = false;
            if (element.templateData.fxScope)
                patch.path.includes(element.templateData.fxScope.concat("/value")) && calculateDebouneced();
            else {
                formula.variables.forEach((v: any) => {
                    try {
                        let myDependency = v.dataSource.id
                        if (myDependency != element.id && patch.path.includes(myDependency.concat("/value")))
                            mustRecalculate = true;
                    } catch (e) {
                        mustRecalculate = false;
                    }

                });
                if (mustRecalculate)
                    calculateDebouneced();
            }


        });

        return () => {
            _disposerOnPatch();
        }
    }, [])
    const btnRefresh = <></>;//(<IconButton onPress={calculateDebouneced} icon={<Icon as={Ionicons} name="calculator" size="md" />} />)

    return <ElementBase errors={errorList} element={element} rigthButton={btnRefresh} forReview={props.forReview} >
        <HStack alignItems="center" marginVertical={2} >
            <Icon as={MaterialCommunityIcons} name="function-variant" mr="$2" /><Text>{payload.value}</Text>
        </HStack>
    </ElementBase>
});