import React, { FC } from "react"
import { observer } from "mobx-react-lite"

import { Text, StatusBar, Icon, Box, Button, ButtonIcon, ScrollView, HStack, VStack } from "@gluestack-ui/themed"
import { MaterialIcons, Ionicons, Zocial } from "@expo/vector-icons";
import { HomeStackParamList, useBackButtonHandler } from "../../navigators"
import { DrawerScreenProps } from "@react-navigation/drawer"
import { useStores } from "../../models"
import { ITEM_STATUS } from "../../voolks/types";
import { getIconItem, getItemTitle, } from "../../utils/itemCommonFunctions";
import { GetActionButtonsFC } from "./sections/GetActionButtonsFC";
import { FieldsList } from "../../components/FieldsList";
import { customApp } from "../../config";
import * as Storage from "../../utils/storage";
import { Pressable, TouchableOpacity, View } from "react-native";
import { ModalFull } from "../../components";
import PrinterDialog from "../PrinterDialog";



export const ItemDetailsScreen: FC<DrawerScreenProps<HomeStackParamList, "ItemDetails">> = (function ItemDetailsScreen({ navigation, route }) {
  const TAG_NAME = "ItemDetailsScreen";
  const [shouldHide, setShouldHide] = React.useState(false);

  const [showPrintDialog, setShowPrintDialog] = React.useState(false);
  const [hasNotif, setHasNotif] = React.useState(undefined);
  const [formView, setFormView] = React.useState<any>(<VStack></VStack>);

  useBackButtonHandler(() => () => console.log("Omitir atras"));
  if (shouldHide)
    return <></>;
  const { formsStore, itemsStore, statusStore } = useStores();
  const department = React.useMemo(() => formsStore.mySubscriptions().subscriptions.find((item: any) => item.id == route.params.deptId), []);
  if (department == undefined) {
    navigation.navigate("Settings");
    return <><Text></Text></>
  }
  const form = department.forms.find((form: any) => form.id == route.params.formId);
  if (form == undefined) {
    navigation.navigate("FormsList", { deptId: department.id });
    return <><Text></Text></>
  }

  function _getScreenName(isPTW, formType, defaultName) {
    if (isPTW) {
      return "PTWHome";
    }
    if (formType == "workflow") {
      return "WFHome";
    }
    if (formType == "measurement") {
      return "MeasurementHome";
    }
    return defaultName;
  }

  const ItemsListScreenName = _getScreenName(customApp.isPTW, form.type, "ItemsList");
  const FormsListScreenName = _getScreenName(customApp.isPTW, form.type, "FormsList");

  const taskId = route.params.taskId;

  const [pageActive, setPageActive] = React.useState<number>(route.params.pageActive ?? 0);
  if (itemsStore.itemActiveIDX == undefined || itemsStore.itemActive == null || itemsStore.itemActive.payload == null) {
    navigation.navigate(FormsListScreenName);
    return <></>;
  }

  if (route.params.forReview != true && itemsStore.itemActive.status != "draft")
    // @ts-ignore
    navigation.navigate(ItemsListScreenName, { deptId: department.id, formId: form.id, taskId });

  const saveAndGo = async ({ goTo = "", itemStatus = undefined, forReview = false } = {}) => {
    console.log("saveAndGo")
    if (itemStatus != undefined)
      await itemsStore.saveItemActive(itemStatus);
    if (goTo == "") {
      goTo = ItemsListScreenName;
      const itemStatus = itemsStore.itemActive.status;
      if (itemStatus == ITEM_STATUS.draft && route.params.forReview) {
        goTo = "ItemDetails";
        forReview = false;
      }
    }
    setPageActive(0);
    // @ts-ignore
    navigation.navigate(goTo, { deptId: department.id, formId: form.id, forReview, taskId })
  }

  let leftButton = <Button width={50} height={50} p="$1" variant="solid" key="actionButton-nextPage" onPress={() => saveAndGo({ itemStatus: (itemsStore.itemActive.status) })}>
    <ButtonIcon as={Ionicons} name="arrow-back" fontWeight="$extrabold" size="xl" color="#FFF" />
  </Button>;
  const IconItemStatus: FC = observer(function iconItemStatus() {
    return getIconItem(itemsStore.itemActive.status, undefined, "sm");
  })

  const makeForm = () => {
    console.log(TAG_NAME, "makeForm")
    setFormView(<>
      <StatusBar backgroundColor={"#3700B3"} barStyle="light-content" />
      <Box bg="$primary400" />
      <HStack key="itemDetails-title" bg="$primary400" px={0} py={0} justifyContent="space-between" alignItems="center" w="100%">
        <HStack key="headerBar" alignItems="center" style={{ flexDirection: 'row', flex: 0.8 }} maxWidth={statusStore.windowDimensions.width - 140}>
          {leftButton}
          <View style={{ flexDirection: 'row', flex: 1 }}>
            <Text color="$white" fontSize={18} style={{ flexWrap: 'wrap', overflow: "hidden" }}>
              {form.name}
            </Text>
          </View>
        </HStack>
        <HStack key="secondBar" alignItems="center" style={{ flexDirection: 'row-reverse', flex: 0.2, }}>
          <GetActionButtonsFC itemsStore={itemsStore}
            pageActive={pageActive}
            navigation={navigation}
            setShouldHide={setShouldHide}
            form={form}
            saveAndGo={saveAndGo}
            forReview={route.params.forReview}
            ItemsListScreenName={ItemsListScreenName}
          />
          {form.pages.length > 1 ?
            <>
              <Button width={50} height={50} pl="$1" pr="$2.5" isDisabled={pageActive == (form.pages.length - 1)} variant="solid" key="actionButton-nextPage" onPress={() => { setPageActive(pageActive + 1); }}>
                <ButtonIcon as={Ionicons} name="caret-forward" color="#FFF" size="xl" />
              </Button>
              <Button width={50} height={50} pr="$1" pl="$2.5" isDisabled={pageActive == 0} variant="solid" key="actionButton-prevPage" onPress={() => { setPageActive(pageActive - 1); }}>
                <ButtonIcon as={Ionicons} name="caret-back" color="#FFF" size="xl" />
              </Button>
            </>
            : <></>
          }

        </HStack>
      </HStack>
      <HStack key="itemDetails-subTitle" bg="$primary300" px={2} py={1} alignItems="center" w="100%">
        <Text><IconItemStatus /></Text><Text color="$white" fontSize="$2xs">{getItemTitle(form, itemsStore.itemActive)} </Text>
        <View />
        {itemsStore.itemActive.taskId ? <Icon name="event" size="md" color={"secondary.400"} as={MaterialIcons} /> : <></>}
        {hasNotif != undefined ? <Pressable onPress={() => { statusStore.setAlertMessage(hasNotif.notification.title, hasNotif.notification.body) }}>
          <Icon as={Ionicons} name="notifications-circle" size="md" color="yellow.400" />
        </Pressable> : <></>}
      </HStack>
      <ScrollView key="itemDetails-scrollItems">
        <VStack key="itemDetails-VStackItems" bg="$white" flex={1}>
          <FieldsList itemsStore={itemsStore}
            fields={form.pages[pageActive]?.fields}
            forReview={route.params.forReview} scroll={true} />
        </VStack>
      </ScrollView>
    </>)
  }

  const checkNotif = async () => {
    if (itemsStore.itemActive.id) {
      const data = await Storage.load("notif-".concat(itemsStore.itemActive.id));
      setHasNotif(data);
      console.log("notif", data);
    }
  }


  const printMe = async () => {
    setShowPrintDialog(true);
  }


  React.useEffect(() => {
    makeForm();
  }, [pageActive, route.params, hasNotif])

  React.useEffect(() => {
    checkNotif();
  }, []);

  return <VStack height="$full">
    {formView}
    <TouchableOpacity
      style={{
        borderWidth: 1,
        borderColor: '#f00',
        alignItems: 'center',
        justifyContent: 'center',
        width: 60,
        position: 'absolute',
        bottom: 0,
        right: 10,
        height: 60,
        backgroundColor: '#fff',
        borderRadius: 100,
        paddingBottom: 5,
        display: itemsStore.itemActive.status == "draft" ? "none" : "flex"

      }}
      onPress={printMe}
    >
      <Icon as={Zocial} name="print" size="xl" color="$success400" style={{ paddingBottom: 0, height: 30 }} />
    </TouchableOpacity>
    {showPrintDialog && <ModalFull
      visible={showPrintDialog}
      title="Comprobante Móvil"
      closeButton_onPress={() => { setShowPrintDialog(false); }}
      footer={<>

      </>
      }>
      <View width="100%" height="100%" bgColor={"white"} alignSelf={"center"} borderWidth={1}>
        <PrinterDialog form={form}></PrinterDialog>
      </View>
    </ModalFull>
    }</VStack>
})