import React from "react"
import { createDrawerNavigator } from "@react-navigation/drawer";
import { observer } from "mobx-react-lite"
import {
    SettingsScreen, ItemsListScreen, FormsListScreen, TasksListScreen
} from "../screens"
import { SideMenu } from "../components/SideBar";
import { AppStackParamList } from "./AppNavigator";
import { ItemDetailsScreen } from "../screens/Forms/ItemDetailsScreen";
import { TaskDetailsScreen } from "../screens/Tasks/TaskDetailsScreen";
import { useWindowDimensions } from "react-native";
import { useStores } from "../models";
import { PTWHomeScreen } from "../screens/PTW/PTWHomeScreen";
import { MeasurementScreen } from "../screens/PTW/MeasurementScreen";
import { WorkflowsScreen } from "../screens/WorkflowsScreen";
import { customApp } from "../config";

export type HomeStackParamList = AppStackParamList & {
    Settings: { fromForm: boolean },
    TasksList: {},
    TaskDetails: { taskId: string, fromTaskList: boolean, startDate?: Date | undefined },
    FormsList: { deptId: string },
    PTWHome: { rnd?: number; },
    MeasurementHome: { rnd?: number; },
    WFHome: { rnd?: number; },
    ItemsList: {
        deptId: string;
        formId: string;
        taskId?: string;
        forReview?: boolean;
    },
    ItemDetails: {
        deptId: string;
        formId: string;
        taskId?: string;
        forReview: boolean;
        pageActive: number;
        itemStartedAt: number;
        rnd: number;
    },
};

const Drawer = createDrawerNavigator<HomeStackParamList>();
export const NAVIGATION_PERSISTENCE_KEY = "HOME_NAVIGATION_STATE"
export const HomeNavigator = observer(function HomeNavigator() {
    const dimensions = useWindowDimensions();
    const { formsStore, statusStore } = useStores();
    let subscriptions = [];
    let isLargeScreen = false;
    let initialRouteName: keyof HomeStackParamList = "FormsList"
    let _drawerContent = undefined;
    console.log("HomeNavigator", "ACA PASE1")
    if (customApp.isPTW==true) {
        initialRouteName = "PTWHome";
    } else if (customApp.isMeasurement ==true) {
        initialRouteName = "MeasurementHome";
    } else {
        _drawerContent = SideMenu;
        subscriptions = JSON.parse(JSON.stringify(formsStore.mySubscriptions().subscriptions));
        isLargeScreen = dimensions.width >= 1600;
    }

    console.log("ACA PASE1", initialRouteName)
    return (<Drawer.Navigator
        id="HomeDrawer" drawerContent={_drawerContent} screenOptions={{ headerShown: false, drawerType: isLargeScreen ? 'permanent' : 'back', overlayColor: "transparent", }}
        detachInactiveScreens={true} initialRouteName={initialRouteName} >
        <Drawer.Screen key="Settings" name="Settings" component={SettingsScreen} options={{ unmountOnBlur: true, title: "Configuraciones" }} />
        <Drawer.Screen key="TasksList" name="TasksList" component={TasksListScreen} options={{ unmountOnBlur: true, title: "Calendario de Tareas" }} />
        <Drawer.Screen key="TaskDetails" name="TaskDetails" component={TaskDetailsScreen} options={{ unmountOnBlur: true, title: "Detalle de Tarea" }} />
        <Drawer.Screen key="FormsList" initialParams={{ deptId: subscriptions.length > 0 ? subscriptions[0].id : null }} name="FormsList" component={FormsListScreen} options={{ unmountOnBlur: true, title: "Lista de Formularios" }} />
        <Drawer.Screen key="ItemsList" name="ItemsList" component={ItemsListScreen} options={{ unmountOnBlur: true, title: "Lista de Items" }} />
        <Drawer.Screen key="ItemDetails" name="ItemDetails" component={ItemDetailsScreen} options={{ unmountOnBlur: true, title: "Detalle del Item" }} />
        <Drawer.Screen key="PTWHome" name="PTWHome" initialParams={{ rnd: new Date().getTime() }} component={PTWHomeScreen} options={{ unmountOnBlur: true, title: "Permisos de Trabajo" }} />
        <Drawer.Screen key="MeasurementHome" name="MeasurementHome" initialParams={{ rnd: new Date().getTime() }} component={MeasurementScreen} options={{ unmountOnBlur: true, title: "Permisos de Trabajo" }} />
        <Drawer.Screen key="WFHome" name="WFHome" initialParams={{ rnd: new Date().getTime() }} component={WorkflowsScreen} options={{ unmountOnBlur: true, title: "Workflows" }} />
    </Drawer.Navigator >);
})